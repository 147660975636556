/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

import MetaTags from 'react-meta-tags';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
//import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import LottoPsychicNavbar from "layouts/LottoPsychicNavbar";
import LottoPsychicFooter from "layouts/LottoPsychicFooter";

// Presentation page sections
import LottoFrequencyChart from "components/LottoFrequencyChart";

import breakpoints from "assets/theme/base/breakpoints";

// Routes
import routes from "../../routes";

// Images
import bgImage from "assets/images/pexels-pixabay-258154.webp";

import { useEffect, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import _ from "lodash";

import { PowerballBallFrequency } from "../../data/powerball/ballFrequency";
import { PowerballPowerballFrequency } from "../../data/powerball/powerballFrequency";

const WhiteBall = (props) => {
  return (
    <Avatar
      sx={{
        background: "radial-gradient(circle at 50% 25%,#fff,#bbb)",
        border: "1px solid #d2d2d2",
        perspective: "600px",
        color: "#101820",
      }}
      {...props}
    >
      {props.number !== 0 && props.number}
    </Avatar>
  );
};
WhiteBall.propTypes = {
  number: PropTypes.number,
};
const RedBall = (props) => {
  return (
    <Avatar sx={{ bgcolor: "#c8102e" }} {...props}>
      {props.number !== 0 && props.number}
    </Avatar>
  );
};
RedBall.propTypes = {
  number: PropTypes.number,
};

const GenerateButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#e30c07"),
  borderRadius: 28,
  backgroundColor: "#e30c07",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "#c8102e",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#c8102e",
  },
  "&:focus": {
    boxShadow: "none",
    backgroundColor: "#c8102e",
  },
  "&:focus:not(:hover)": {
    boxShadow: "none",
    backgroundColor: "#e30c07",
  },
}));

const GeneratorCard = (props) => {
  const [balls, setBalls] = useState(props.balls);
  const [powerball, setPowerball] = useState(props.powerball);

  return (
    <Card sx={{ maxWidth: 400 }}>
      <CardContent>
        <Stack
          direction="row"
          spacing={{ xs: 0.5, md: 0.5, lg: 2 }}
          style={{ justifyContent: "center" }}
        >
          <WhiteBall number={balls[0]} {...props} />
          <WhiteBall number={balls[1]} {...props} />
          <WhiteBall number={balls[2]} {...props} />
          <WhiteBall number={balls[3]} {...props} />
          <WhiteBall number={balls[4]} {...props} />
          <RedBall number={powerball} {...props} />
        </Stack>
      </CardContent>
      <CardActions style={{ justifyContent: "center" }}>
        <GenerateButton
          variant="contained"
          onClick={() => {
            const generatedNumbers = props.generator();
            setBalls(generatedNumbers.balls.sort((a, b) => a - b));
            setPowerball(generatedNumbers.powerball);
          }}
        >
          Generate
        </GenerateButton>
      </CardActions>
    </Card>
  );
};
GeneratorCard.defaultProps = {
  balls: [0, 0, 0, 0, 0],
  powerball: 0,
};
GeneratorCard.propTypes = {
  balls: PropTypes.arrayOf(PropTypes.number),
  powerball: PropTypes.number,
  generator: PropTypes.func,
};

// Random Method
function randomPowerballGenerator() {
  const ballsSet = new Set();
  while (ballsSet.size !== 5) {
    ballsSet.add(Math.floor(Math.random() * 69) + 1);
  }

  return {
    balls: Array.from(ballsSet),
    powerball: Math.floor(Math.random() * 26) + 1,
  };
}

// weighted ball generator
function weightedPowerballGenerator(ballWeights, powerballWeights) {
  const ballsSet = new Set();
  const ballMaxCumulativeWeight = ballWeights[ballWeights.length - 1];
  while (ballsSet.size !== 5) {
    const ballRandomNumber = ballMaxCumulativeWeight * Math.random();
    for (let i = 0; i < ballWeights.length; i++) {
      if (ballWeights[i] >= ballRandomNumber) {
        ballsSet.add(i + 1);
        break;
      }
    }
  }

  let powerball = 0;
  const powerballMaxCumulativeWeight = powerballWeights[powerballWeights.length - 1];
  const powerballRandomNumber = powerballMaxCumulativeWeight * Math.random();
  for (let i = 0; i < powerballWeights.length; i++) {
    if (powerballWeights[i] >= powerballRandomNumber) {
      powerball = i + 1;
      break;
    }
  }
  return {
    balls: Array.from(ballsSet),
    powerball: powerball,
  };
}

function createPowerBallOddPrizeData(whiteballs, redball, prize, odds) {
  return { whiteballs, redball, prize, odds };
}
const powerBallOddPrizeData = [
  createPowerBallOddPrizeData(5, 1, "Grand Prize", "1 in 292,201,338.00"),
  createPowerBallOddPrizeData(5, 0, "$1 Million", "1 in 11,688,053.52"),
  createPowerBallOddPrizeData(4, 1, "$50,000", "1 in 913,129.18"),
  createPowerBallOddPrizeData(4, 0, "$100", "1 in 36,525.17"),
  createPowerBallOddPrizeData(3, 1, "$100", "1 in 14,494.11"),
  createPowerBallOddPrizeData(3, 0, "$7", "1 in 579.76"),
  createPowerBallOddPrizeData(2, 1, "$7", "1 in 701.33"),
  createPowerBallOddPrizeData(1, 1, "$4", "1 in 91.98"),
  createPowerBallOddPrizeData(0, 1, "$4", "1 in 38.32"),
];
const powerBallOddPrizeMatchRender = (row) => {
  const balls = [];
  for (let i = 0; i < row.whiteballs; i++) {
    balls.push(
      <WhiteBall
        number={0}
        sx={{
          background: "radial-gradient(circle at 50% 25%,#fff,#bbb)",
          border: "1px solid #d2d2d2",
          perspective: "600px",
          color: "#101820",
          width: {
            xs: 24,
            lg: 40,
          },
          height: {
            xs: 24,
            lg: 40,
          },
        }}
      />
    );
  }
  if (row.redball === 1) {
    balls.push(
      <RedBall
        number={0}
        sx={{
          bgcolor: "#c8102e",
          width: {
            xs: 24,
            lg: 40,
          },
          height: {
            xs: 24,
            lg: 40,
          },
        }}
      />
    );
  }
  return (
    <Stack direction="row" spacing={0}>
      {balls}
    </Stack>
  );
};
const powerBallOddPrizeMatchMobileRender = () => {
  const mobileRender = [];
  for (let i = 0; i < powerBallOddPrizeData.length; i++) {
    const row = powerBallOddPrizeData[i];
    const balls = powerBallOddPrizeMatchRender(row);
    mobileRender.push(
      <TableContainer component={Paper} sx={{ m: 1, p: 1 }}>
        <Table>
          <TableBody responsive="vertical">
            <TableRow key={"OddPrizeDataMatch" + i}>
              <TableCell variant="head" align="left" width="30%">
                Match
              </TableCell>
              <TableCell align="right" sx={{ display: "flex", justifyContent: "right" }}>
                {balls}
              </TableCell>
            </TableRow>
            <TableRow key={"OddPrizeDataPrize" + i}>
              <TableCell variant="head" align="left" width="30%">
                Prize
              </TableCell>
              <TableCell align="right">{row.prize}</TableCell>
            </TableRow>
            <TableRow key={"OddPrizeDataOdds" + i}>
              <TableCell variant="head" align="left" width="30%">
                Odds
              </TableCell>
              <TableCell align="right">{row.odds}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  return <>{mobileRender}</>;
};

function Powerball() {
  const [ballFrequencyData, setBallFrequencyData] = useState([]);
  const [powerballFrequencyData, setPowerballFrequencyData] = useState([]);
  const [mobileViewForOddsTable, setMobileViewForOddsTable] = useState(false);

  // bias method
  const [ballFrequencyBiasedCumulativeWeights, setBallFrequencyBiasedCumulativeWeights] = useState([]);
  const [powerballFrequencyBiasedCumulativeWeights, setPowerballFrequencyBiasedCumulativeWeights] = useState([]);
  // eventual average method
  const [ballAverageBiasedCumulativeWeights, setBallAverageBiasedCumulativeWeights] = useState([]);
  const [powerballAverageBiasedCumulativeWeights, setPowerballAverageBiasedCumulativeWeights] = useState([]);

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobilePrizeOddsTable() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileViewForOddsTable(true);
      } else {
        setMobileViewForOddsTable(false);
      }
    }

    displayMobilePrizeOddsTable();

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobilePrizeOddsTable);

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobilePrizeOddsTable);
  }, []);
  
  useEffect(() => {
    axios.get("/data/powerball/ballFrequency.json").then((res) => {
      var frequencyData = [];
      for (let i = 0; i < res.data.length; i++) {
        frequencyData.push({
          ball: (i + 1),
          frequency: res.data[i]
        });
      }
      setBallFrequencyData(frequencyData);
      
      // set biasedCumulativeWeight
      const biasedCumulativeWeights = [];
      for (let i = 0; i < frequencyData.length; i++) {
        biasedCumulativeWeights[i] =
        frequencyData[i].frequency + (biasedCumulativeWeights[i - 1] || 0);
      }
      setBallFrequencyBiasedCumulativeWeights(biasedCumulativeWeights);
      // set averageWeight
      const ballAverageFrequency = Math.round(_.mean(_.map(frequencyData, "frequency")));
      const averageCumulativeWeights = [];
      for (let i = 0; i < frequencyData.length; i++) {
        let currentFrequency = frequencyData[i].frequency;
        averageCumulativeWeights[i] =
          (currentFrequency >= ballAverageFrequency
            ? Math.max(ballAverageFrequency - (currentFrequency - ballAverageFrequency), 1)
            : ballAverageFrequency + (ballAverageFrequency - currentFrequency)) +
            (averageCumulativeWeights[i - 1] || 0);
      }
      setBallAverageBiasedCumulativeWeights(averageCumulativeWeights);
    });
    axios.get("/data/powerball/powerballFrequency.json").then((res) => {
      var frequencyData = [];
      for (let i = 0; i < res.data.length; i++) {
        frequencyData.push({
          ball: (i + 1),
          frequency: res.data[i]
        });
      }
      setPowerballFrequencyData(frequencyData);

      // set biasedCumulativeWeight
      const biasedCumulativeWeights = [];
      for (let i = 0; i < frequencyData.length; i++) {
        biasedCumulativeWeights[i] =
        frequencyData[i].frequency + (biasedCumulativeWeights[i - 1] || 0);
      }
      setPowerballFrequencyBiasedCumulativeWeights(biasedCumulativeWeights);
      // set averageWeight
      const ballAverageFrequency = Math.round(_.mean(_.map(frequencyData, "frequency")));
      const averageCumulativeWeights = [];
      for (let i = 0; i < frequencyData.length; i++) {
        let currentFrequency = frequencyData[i].frequency;
        averageCumulativeWeights[i] =
          (currentFrequency >= ballAverageFrequency
            ? Math.max(ballAverageFrequency - (currentFrequency - ballAverageFrequency), 1)
            : ballAverageFrequency + (ballAverageFrequency - currentFrequency)) +
            (averageCumulativeWeights[i - 1] || 0);
      }
      setPowerballAverageBiasedCumulativeWeights(averageCumulativeWeights);
    });
  }, []);

  return (
    <>
      <MetaTags>
        <title>LottoPsychic - Powerball Analytics/Predictor</title>
        <meta name="description" content="LottoPsychic is the best free lottery analytics tool. Analyze Powerball numbers and predict your next winning numbers." />
      </MetaTags>
      <LottoPsychicNavbar routes={routes} />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Powerball Predictor
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Best Free Powerball Number Analytics Tool
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" pt={12} pb={1}>
          <Container>
            <Grid container alignItems="center">
              <Grid item xs={12} lg={12} id="overview">
                <MKTypography variant="h3" my={1}>
                  Powerball Overview
                </MKTypography>
                <MKTypography variant="body2" color="text" mb={2}>
                  Powerball is a popular American lottery game offered by 45 states, the District of
                  Columbia, Puerto Rico, and the U.S. Virgin Islands. It is overseen by the
                  Multi-State Lottery Association (MUSL), which also manages other large jackpot
                  games such as Mega Millions. Powerball drawings are held three times a week:
                  Monday, Wednesday, and Saturday at 10:59 PM ET.
                </MKTypography>
                <MKTypography variant="h4" color="text" mb={2}>
                  How to Play
                </MKTypography>
                <MKTypography variant="body2" color="text" mb={2}>
                  To play Powerball, you must be at least 18 years old (19 in Alabama and Nebraska,
                  21 in Washington state) and purchase a ticket from an authorized retailer. Tickets
                  cost $2 per play, and you can add the Power Play option for an additional $1. To
                  select your numbers, choose five white balls from a pool of 69 and one red
                  Powerball from a pool of 26. You can either pick your own numbers or have the
                  computer randomly select them for you.
                </MKTypography>
                <MKTypography variant="h4" color="text" mb={2}>
                  Odds of Winning and Prizes
                </MKTypography>
                <MKTypography variant="body2" color="text" mb={2}>
                  There are nine ways to win a prize in Powerball, ranging from matching just the
                  Powerball to matching all five white balls and the Powerball. The jackpot is won
                  by matching all six numbers, and it starts at $20 million and grows until it is
                  won. The jackpot can be paid out as an annuity over 29 years or as a lump sum
                  payment.
                </MKTypography>
                <MKTypography variant="body2" color="text" mb={2}>
                  The overall odds of winning any prize in Powerball are 1 in 24.9. The odds of
                  winning the jackpot are 1 in 292.2 million.
                </MKTypography>
                {mobileViewForOddsTable === false ? (
                  <TableContainer component={Paper} sx={{ m: 3, p: 3 }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead sx={{ display: "table-header-group" }}>
                        <TableRow>
                          <TableCell align="left">Match</TableCell>
                          <TableCell align="center">Prize</TableCell>
                          <TableCell align="center">Odds</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody responsive="vertical">
                        {powerBallOddPrizeData.map((row, i) => (
                          <TableRow key={"OddPrizeData" + i}>
                            <TableCell>{powerBallOddPrizeMatchRender(row)}</TableCell>
                            <TableCell align="center">{row.prize}</TableCell>
                            <TableCell align="center">{row.odds}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <>{powerBallOddPrizeMatchMobileRender()}</>
                )}
                <MKTypography variant="h4" color="text" mb={2}>
                  Power Play
                </MKTypography>
                <MKTypography variant="body2" color="text" mb={2}>
                  The Power Play option is an additional $1 that can be added to your Powerball
                  ticket. If you win a prize, Power Play will multiply your winnings by a number
                  that is randomly drawn before each drawing. The Power Play multiplier can range
                  from 2x to 10x.
                </MKTypography>
                <MKTypography variant="h4" color="text" mb={2}>
                  Taxes
                </MKTypography>
                <MKTypography variant="body2" color="text" mb={2}>
                  Powerball winnings are subject to federal and state taxes. The federal tax
                  withholding rate is 24%, and the state tax withholding rate varies depending on
                  the state.
                </MKTypography>
                <MKTypography variant="h4" color="text" mb={2}>
                  Where to Buy Tickets
                </MKTypography>
                <MKTypography variant="body2" color="text" mb={2}>
                  Powerball tickets can be purchased at authorized retailers in all 45 participating
                  states, the District of Columbia, Puerto Rico, and the U.S. Virgin Islands. You
                  can also purchase tickets online in some states.
                </MKTypography>
                <MKTypography variant="h4" color="text" mb={2}>
                  Drawing Results
                </MKTypography>
                <MKTypography variant="body2" color="text" mb={2}>
                  Powerball drawing results are available on the Powerball website and on most
                  lottery websites and apps.
                </MKTypography>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
        <MKBox component="section" py={{ xs: 1, md: 1 }}>
          <Container>
            <Grid container alignItems="center">
              <Grid item xs={12} lg={12} id="analytics">
                <MKTypography variant="h3" my={1}>
                  Analytics
                </MKTypography>
                <MKTypography variant="body2" color="text" mb={2}>
                  Analyzing Powerball frequency is the process of studying the historical frequency
                  of each number drawn in the lottery game. This information can be used to identify
                  trends and patterns that may help players make more informed decisions when
                  selecting their numbers.
                </MKTypography>
                <MKTypography variant="body2" color="text" mb={2}>
                  There are a number of different ways to analyze Powerball frequency. One common
                  approach is to simply count the number of times each number has been drawn in the
                  past. This can be done for individual numbers, pairs of numbers, or even larger
                  groups of numbers.
                </MKTypography>
                <MKTypography variant="body2" color="text" mb={2}>
                  Another approach is to use statistical methods to analyze the frequency data. For
                  example, players can calculate the average frequency of each number, the standard
                  deviation, and the correlation between different numbers. This information can be
                  used to identify numbers that are more likely to be drawn together, as well as
                  numbers that are less likely to be drawn together.
                </MKTypography>
                <MKTypography variant="body2" color="text" mb={2}>
                  Players can also use Powerball frequency analysis to identify patterns over time.
                  For example, players can look at the frequency of numbers over the past year, the
                  past five years, or even the entire history of the game. This information can be
                  used to identify numbers that have been trending up or down in frequency.
                </MKTypography>
                <MKTypography variant="body2" color="text" mb={2}>
                  It is important to note that Powerball is a random game, and there is no
                  guaranteed way to predict which numbers will be drawn in the next drawing.
                  However, analyzing Powerball frequency can give players an edge by helping them to
                  identify numbers that are more likely to be drawn.
                </MKTypography>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <LottoFrequencyChart title={"Numbers by Frequency"} data={ballFrequencyData} />
                  </Grid>
                  <Grid item xs={12}>
                    <LottoFrequencyChart
                      title={"Powerball by Frequency"}
                      data={powerballFrequencyData}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
        <MKBox component="section" py={{ xs: 3, md: 2 }}>
          <Container>
            <Grid container alignItems="center">
              <Grid item xs={12} lg={12} id="predictor">
                <MKTypography variant="h3" my={1}>
                  Number Generators
                </MKTypography>
                <Grid container>
                  <Grid item xs={12} md={7}>
                    <MKTypography variant="h4" my={1}>
                      Random Picker
                    </MKTypography>
                    <MKTypography variant="body2" color="text" mb={2} sx={{ mr: 1 }}>
                      This generator randomly picks Powerball numbers. This can be useful for
                      players who do not want to pick their own numbers or who are looking for a
                      more objective way to choose their numbers.
                    </MKTypography>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <GeneratorCard generator={randomPowerballGenerator} />
                  </Grid>
                </Grid>
                <Grid container sx={{ mt: 3 }}>
                  <Grid item xs={12} md={7}>
                    <MKTypography variant="h4" my={1}>
                      Bias Towards Frequency
                    </MKTypography>
                    <MKTypography variant="body2" color="text" mb={2} sx={{ mr: 1 }}>
                      This generator randomly picks Powerball numbers, but with a bias towards the
                      numbers that have been drawn more often in the past. This can be useful for
                      players who believe that the more frequently a number has been drawn in the
                      past, the more likely it is to be drawn in the future.
                    </MKTypography>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <GeneratorCard
                      generator={function () {
                        return weightedPowerballGenerator(
                          ballFrequencyBiasedCumulativeWeights,
                          powerballFrequencyBiasedCumulativeWeights
                        );
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container sx={{ mt: 3 }}>
                  <Grid item xs={12} md={7}>
                    <MKTypography variant="h4" my={1}>
                      Working Towards Average
                    </MKTypography>
                    <MKTypography variant="body2" color="text" mb={2} sx={{ mr: 1 }}>
                      This generator randomly picks Powerball numbers, but with a bias towards the
                      numbers that have been drawn less often in the past. This can be useful for
                      players who believe that the less frequently a number has been drawn in the
                      past, the more likely it is to be drawn in the future.
                    </MKTypography>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <GeneratorCard
                      generator={function () {
                        return weightedPowerballGenerator(
                          ballAverageBiasedCumulativeWeights,
                          powerballAverageBiasedCumulativeWeights
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <LottoPsychicFooter />
      </MKBox>
    </>
  );
}

export default Powerball;
