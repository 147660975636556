/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
// import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const date = new Date().getFullYear();

function LottoPsychicFooter() {
  return (
    <MKBox component="footer">
      <Container>
        <Grid item xs={12} sx={{ textAlign: "center", my: 1 }}>
          <MKTypography fontWeight="regular" lineHeight={1} fontSize={10}>
            The information provided on this site is for entertainment purposes only. It is not
            intended to be used as a substitute for professional advice or guidance. The author of
            this site makes no guarantees or representations as to the accuracy, completeness, or
            timeliness of the information provided. The author is not responsible for any losses or
            damages resulting from the use or reliance on the information provided on this site. The
            author of this site encourages readers to do their own research and consult with
            qualified professionals before making any decisions based on the information provided on
            this site. Specifically, the author of this site does not guarantee that using any of
            the information provided on this site will result in winning the lottery. Please play
            responsibly.
          </MKTypography>
          <MKTypography variant="button" fontWeight="regular">
            All rights reserved. Copyright &copy; {date} Lotto Psychic
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

// Typechecking props for the DefaultFooter
LottoPsychicFooter.propTypes = {};

export default LottoPsychicFooter;
