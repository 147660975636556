/* eslint-disable no-param-reassign */
/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
//import Container from "@mui/material/Container";
//import Icon from "@mui/material/Icon";
//import Popper from "@mui/material/Popper";
//import Grow from "@mui/material/Grow";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
//import Divider from "@mui/material/Divider";
//import MuiLink from "@mui/material/Link";

// Material Kit 2 React components
//import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
//import MKButton from "components/MKButton";
import breakpoints from "assets/theme/base/breakpoints";
import { BarChart, Bar, Brush, ReferenceLine, XAxis, YAxis, ResponsiveContainer } from "recharts";
import { maxBy } from "lodash";

function LottoFrequencyChart({ data, title }) {
  const [maxFrequency, setMaxFrequency] = useState(100);
  const [frequencyData, setFrequencyData] = useState(data);
  const [frequencyDataKey, setFrequencyDataKey] = useState("");
  const [frequencyShowByNumber, setFrequencyShowByNumber] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  useEffect(() => {
    setFrequencyData(data);
    if (data.length !== 0) {
      setMaxFrequency(maxBy(data, "frequency").frequency * 1.1);
    }
  }, [data]);

  useEffect(() => {
    let currentTime = new Date();
    if (frequencyShowByNumber) {
      setFrequencyData(frequencyData.sort((a, b) => a.ball - b.ball));
      setFrequencyDataKey("bfd_key_" + currentTime.getTime());
    } else {
      setFrequencyData(frequencyData.sort((a, b) => b.frequency - a.frequency));
      setFrequencyDataKey("bfd_key_" + currentTime.getTime());
    }
  }, [frequencyShowByNumber, frequencyData]);

  return (
    <>
      <Grid container direction="column" alignItems="center" justifyContent="center">
        <MKTypography variant="h4" my={1}>
          {title}
        </MKTypography>
        <Grid component="label" container alignItems="center" justifyContent="center" spacing={1}>
          <Grid item>
            <MKTypography variant="body2">Order by frequency</MKTypography>
          </Grid>
          <Grid item>
            <Switch
              checked={frequencyShowByNumber}
              onChange={() => {
                setFrequencyShowByNumber(!frequencyShowByNumber);
              }}
              value="checked" // some value you need
            />
          </Grid>
          <Grid item>
            <MKTypography variant="body2">Order by number</MKTypography>
          </Grid>
        </Grid>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            width={500}
            height={300}
            data={frequencyData}
            key={frequencyDataKey}
            maxBarSize={100}
            margin={{
              right: 30,
              left: 20,
            }}
          >
            <XAxis dataKey="ball" tick={{ fontSize: 10 }} interval={0} />
            <YAxis type="number" domain={[0, maxFrequency]} hide={true} />
            <ReferenceLine y={0} stroke="#000" />
            {((mobileView && frequencyData.length >= 10) || frequencyData.length >= 30) && (
              <Brush
                dataKey="ball"
                height={20}
                stroke="#8884d8"
                endIndex={mobileView ? 10 : 30}
                tickFormatter={() => {
                  return "";
                }}
              />
            )}
            <Bar
              dataKey="frequency"
              fill="#8884d8"
              label={{ position: "top", fontSize: "10" }}
              key={frequencyDataKey}
            />
          </BarChart>
        </ResponsiveContainer>
      </Grid>
    </>
  );
}

// Setting default values for the props of DefaultNavbar
/*DefaultNavbar.defaultProps = {
  brand: "Material Kit 2",
  transparent: false,
  light: false,
  action: false,
  sticky: false,
  relative: false,
  center: false,
};*/

// Typechecking props for the DefaultNavbar
LottoFrequencyChart.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape).isRequired,
};
/*DefaultNavbar.propTypes = {
  brand: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.shape).isRequired,
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
        "default",
        "white",
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
  sticky: PropTypes.bool,
  relative: PropTypes.bool,
  center: PropTypes.bool,
};*/

export default LottoFrequencyChart;
